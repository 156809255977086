import mapboxgl from "mapbox-gl";
import axios from "axios";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

export const getRoute = async (home, depot) => {
  const url = `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${home[0]},${home[1]};${depot[0]},${depot[1]}?steps=true&overview=full&geometries=geojson&access_token=${mapboxgl.accessToken}`;

  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
