import React, { useRef, useEffect, useCallback } from "react";
import styles from "./Map.module.scss";

import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

const Map = ({ data, map }) => {
  const mapContainer = useRef(null);

  const initialiseMap = useCallback(() => {
    const route = data.routes[0].geometry.coordinates;

    const geojson = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: route,
      },
    };

    const center = route[Math.floor(route.length / 2)];

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: center,
      zoom: 10,
    });

    map.current.addControl(new mapboxgl.FullscreenControl());
    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    map.current.on("load", async () => {
      const start = geojson?.geometry?.coordinates[0];
      const end =
        geojson?.geometry?.coordinates[
          geojson?.geometry?.coordinates.length - 1
        ];

      if (map.current.getSource("route")) {
        map.current.getSource("route").setData(geojson);
      } else {
        if (map.current.isStyleLoaded()) {
          map.current.addSource("route", {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: geojson?.geometry?.coordinates,
              },
            },
          });
          map.current.addLayer({
            id: "route",
            type: "line",
            source: "route",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#167fc9",
              "line-width": 12,
            },
          });

          //start
          map.current.addLayer({
            id: "start",
            type: "circle",
            source: {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    properties: {},
                    geometry: {
                      type: "Point",
                      coordinates: start,
                    },
                  },
                ],
              },
            },
            paint: {
              "circle-radius": 15,
              "circle-color": "#3dacd4",
            },
          });

          map.current.addLayer({
            id: "A",
            type: "symbol",
            source: "start",
            layout: {
              "text-field": "A",
              "text-font": ["Arial Unicode MS Bold"],
              "text-size": 12,
            },
            paint: {
              "text-color": "#ffffff",
            },
          });

          map.current.addLayer({
            id: "end",
            type: "circle",
            source: {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    properties: {},
                    geometry: {
                      type: "Point",
                      coordinates: end,
                    },
                  },
                ],
              },
            },
            paint: {
              "circle-radius": 15,
              "circle-color": "#907dbd",
            },
          });

          map.current.addLayer({
            id: "B",
            type: "symbol",
            source: "end",
            layout: {
              "text-field": "B",
              "text-font": ["Arial Unicode MS Bold"],
              "text-size": 12,
            },
            paint: {
              "text-color": "#ffffff",
            },
          });
          //end
        }
      }
    });
  }, [map, data]);

  useEffect(() => {
    initialiseMap();
  }, [initialiseMap]);

  return (
    <div>
      <div ref={mapContainer} className={styles.container} />
    </div>
  );
};

export default Map;
