import styles from "./SubFooter.module.scss";

const SubFooter = ({ businessTheme: { domain, local } }) => {
  return (
    <div className={`dpd-row ${styles.subFooter}`}>
      <hr className={styles.line} />
      <div className={styles.container}>
        <div className={`${styles.col1} dpd-columns`}>
          <div>
            <h3 className={styles.h3}>Products &amp; Services</h3>
            <ul className="hide-for-small-only">
              <li className={styles.li}>
                <a
                  className={styles.subAnchor}
                  title="UK"
                  actionid="IzQX7.6C48kIGI6d"
                  href={`${domain}/content/products_services/uk_services.jsp`}
                >
                  UK
                </a>
              </li>
              <li className={styles.li}>
                <a
                  title="Europe &amp; International"
                  className={styles.subAnchor}
                  actionid="nLQX7.6C48kIGI6s"
                  href={`${domain}/content/products_services/international.jsp`}
                >
                  Europe &amp; International
                </a>
              </li>
              <li className={styles.li}>
                <a
                  title="DPD Pickup"
                  className={styles.subAnchor}
                  actionid="LHQX7.6C48kIGI7K"
                  href={`${domain}/content/products_services/dpd_pickup.jsp`}
                >
                  DPD Pickup
                </a>
              </li>
              <li className={styles.li}>
                <a
                  title="Service - Latest"
                  className={styles.subAnchor}
                  actionid="vbQX7.6C48kIGI67"
                  href={`${domain}/content/products_services/service_updates.jsp`}
                >
                  Service - Latest
                </a>
              </li>
              <li className={styles.li}>
                <a
                  href={`${domain}/content/products_services/covid-19-updates.jsp`}
                  title="COVID-19 - Latest"
                  className={styles.subAnchor}
                >
                  COVID-19 - Latest
                </a>
              </li>
              <li className={styles.li}>
                <a
                  title="Delivery Schedule"
                  className={styles.subAnchor}
                  actionid="zE5_7.6C48kIGJLi"
                  href={`${domain}/content/about_dpd/delivery_schedule.jsp`}
                >
                  Delivery Schedule
                </a>
              </li>
              <li className={styles.li}>
                <a
                  title="Cyber Security"
                  className={styles.subAnchor}
                  actionid="IncIH.6C48kIGJXS"
                  href={`${domain}/content/about_dpd/phishing.jsp`}
                >
                  Cyber Security
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={`${styles.col2} dpd-columns`}>
          <div>
            <h3 className={styles.h3}>
              {!local ? "About DPD" : "About DPD Local"}
            </h3>
            <ul>
              {!local && (
                <li className={styles.li}>
                  <a
                    className={styles.subAnchor}
                    title="Awards"
                    actionid="_gEgH.6C48kIGJOp"
                    href={`${domain}/content/about_dpd/awards.jsp`}
                  >
                    Awards
                  </a>
                </li>
              )}
              <li className={styles.li}>
                <a
                  className={styles.subAnchor}
                  title="Brexit"
                  actionid="Nj1gH.6C48kIGJQc"
                  href={`${domain}/content/about_dpd/brexit-ready.jsp`}
                >
                  Brexit
                </a>
              </li>
              <li className={styles.li}>
                <a
                  className={styles.subAnchor}
                  title="CSR"
                  actionid="AxaQH.6C48kIGJR3"
                  href={`${domain}/content/about_dpd/csr.jsp`}
                >
                  CSR
                </a>
              </li>
              {!local && (
                <li className={styles.li}>
                  <a
                    className={styles.subAnchor}
                    title="DPD Blog"
                    actionid="1SLQH.6C48kIGJTJ"
                    href="https://blog.dpd.co.uk/"
                  >
                    DPD Blog
                  </a>
                </li>
              )}
              <li className={styles.li}>
                <a
                  title="Press Centre"
                  className={styles.subAnchor}
                  actionid="NgSwH.6C48kIGJUp"
                  href={`${domain}/content/about_dpd/press_centre/news.jsp`}
                >
                  Press Centre
                </a>
              </li>
              <li className={styles.li}>
                <a
                  className={styles.subAnchor}
                  title="Technology"
                  actionid="jgDwH.6C48kIGJWC"
                  href={`${domain}/content/about_dpd/technology.jsp`}
                >
                  Technology
                </a>
              </li>
              <li className={styles.li}>
                <a
                  className={styles.subAnchor}
                  title="Security"
                  actionid="IncIH.6C48kIGJXS"
                  href={`${domain}/content/about_dpd/security.jsp`}
                >
                  Security
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={`${styles.col3} dpd-columns`}>
          {!local && (
            <div>
              <h3 className={styles.h3}>Careers</h3>
              <ul>
                <li className={styles.li}>
                  <a
                    className={styles.subAnchor}
                    title="Why DPD?"
                    actionid="3JLIH.6C48kIGJYe"
                    href={`${domain}/careers/why-join-us.jsp`}
                  >
                    Why DPD?
                  </a>
                </li>
                <li className={styles.li}>
                  <a
                    className={styles.subAnchor}
                    title="Current Vacancies"
                    actionid="HYTtH.6C48kIGH0D"
                    href={`${domain}/careers/current_jobs.jsp`}
                  >
                    Current Vacancies
                  </a>
                </li>
                <li className={styles.li}>
                  <a
                    title="Owner Driver Franchise"
                    className={styles.subAnchor}
                    actionid="mBWdH.6C48kIGH1r"
                    href="https://drivers.dpd.co.uk"
                  >
                    Owner Driver Franchise
                  </a>
                </li>
              </ul>
            </div>
          )}
          {local && (
            <div>
              <h3 className={styles.h3}>MyDPD Local</h3>
              <ul>
                <li className={styles.li}>
                  <a
                    className={styles.subAnchor}
                    title="Login to MyDPD Local"
                    actionid="RtU9H.6C48kIGH3a"
                    href={`${domain}/umslogon/public/logon.do`}
                  >
                    Login to MyDPD Local
                  </a>
                </li>
                <li className={styles.li}>
                  <a
                    className={styles.subAnchor}
                    title="Sales Enquiry"
                    actionid="cq19H.6C48kIGH4s"
                    href={`${domain}/content/my_dpd/sales-enquiry.jsp`}
                  >
                    Sales Enquiry
                  </a>
                </li>
                <li className={styles.li}>
                  <a
                    title="Billing Information"
                    className={styles.subAnchor}
                    actionid="v5wDH.6C48kIGH6G"
                    href={`${domain}/content/my_dpd/billing_information.jsp`}
                  >
                    Billing Information
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className={`${styles.col4} dpd-columns`}>
          <div>
            <div className={styles.customerService}>
              <h3 className={styles.customerH3}>Service Update</h3>
              <p className={styles.customerP}>
                View the latest postcodes affected for UK deliveries
                <a
                  target="_self"
                  className={
                    !local ? styles.customerAnchor : styles.customerAnchorLocal
                  }
                  actionid="S_zDH.6C48kIGH8V"
                  href={
                    !local
                      ? "https://www.dpd.co.uk/content/products_services/uk_issues.jsp#issue0"
                      : "https://www.dpdlocal.co.uk/content/products_services/service_updates.jsp"
                  }
                >
                  {" "}
                  here
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
