import axios from "axios";

export const checkPostcode = async (appEngine, postcode) => {
  const formattedPostcode = postcode.trim().toUpperCase().replace(/ /g, "");

  const url = `${appEngine}/v1/address?postcode=${formattedPostcode}`;

  try {
    const res = await axios.get(url);
    return res.data.data.address;
  } catch (error) {
    console.log(error);
    return error;
  }
};
