import styles from "./Breadcrumb.module.scss";

const Breadcrumb = ({ local, domain }) => {
  return (
    <div className={`${styles.breadcrumb} dpd-columns`}>
      <ul className={`${styles.breadcrumbUl} ${local ? styles.local : ""}`}>
        <li className={styles.breadcrumbLi}>
          <a
            href={`${domain}/content/how-can-we-help/index.jsp`}
            title="Help"
            className={styles.breadcrumbAnchor}
          >
            Help
          </a>
        </li>
        <li className={`${styles.breadcrumbLi} ${styles.breadcrumbArrow}`}>
          <a
            href="/"
            title="Find My DPD Depot"
            className={styles.breadcrumbAnchor}
          >
            {!local ? "Find My DPD Depot" : "Find My Local Depot"}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Breadcrumb;
