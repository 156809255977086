export const getMiles = (i) => {
  const num = i * 0.000621371192;
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const getMinutes = (num) => {
  return Math.floor(num / 60);
};

export const getDayOfWeek = (index) => {
  let dayOfWeek = "";
  switch (index) {
    case 0:
      dayOfWeek = "Mon";
      break;
    case 1:
      dayOfWeek = "Tues";
      break;
    case 2:
      dayOfWeek = "Wed";
      break;
    case 3:
      dayOfWeek = "Thurs";
      break;
    case 4:
      dayOfWeek = "Fri";
      break;
    case 5:
      dayOfWeek = "Sat";
      break;
    case 6:
      dayOfWeek = "Sun";
      break;

    default:
      break;
  }
  return dayOfWeek;
};
