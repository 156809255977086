import React from "react";

import styles from "./MobileNav.module.scss";

const MobileNav = ({
  showNav,
  setShowNav,
  businessTheme: { local, domain },
}) => {
  return (
    <>
      <div
        className={`${styles.mobileNav} ${
          showNav ? styles.moveLeft : styles.revert
        } dpd-row`}
      >
        <div className="large-12 dpd-columns">
          <nav className={`${styles.tabBar} show-for-small-only`}>
            <section className={styles.tabSection}>
              <h1>Depot Finder</h1>
            </section>
            <section className={styles.tabSection2}>
              <a
                className={styles.menuIcon}
                href="#0"
                aria-expanded="false"
                onClick={() => setShowNav(!showNav)}
              >
                <span></span>
              </a>
            </section>
          </nav>
        </div>
      </div>
      <aside
        className={`${styles.sideNav} ${
          showNav ? styles.showNav : styles.hideNav
        }`}
      >
        <ul className={styles.offUl}>
          <li>
            <a
              title="Home"
              actionid="tY6Fre6C48kIGI8q"
              href={`${domain}/index.jsp`}
            >
              Home
            </a>
            <ul></ul>
          </li>
          <li>
            <a
              title="Products &amp; Services"
              actionid="Avylre6C48kIGOWD"
              href={`${domain}/content/products_services/index.jsp`}
            >
              Products &amp; Services
            </a>
            <ul></ul>
          </li>
          <li>
            <a
              title="Help"
              className={styles.active}
              actionid="0H_lre6C48kIGOX0"
              href={`${domain}/content/how-can-we-help/index.jsp`}
            >
              Help
            </a>
            <ul className={styles.ul2}>
              <li>
                <a
                  href={`${domain}/content/how-can-we-help/index.jsp`}
                  title="Track My Parcel"
                >
                  Track My Parcel
                </a>
              </li>
              <li>
                <a
                  href={
                    local
                      ? process.env.REACT_APP_APP_PICKUP_LOCAL
                      : process.env.REACT_APP_APP_PICKUP
                  }
                  title="DPD Pickup Point Finder"
                >
                  DPD Pickup Point Finder
                </a>
              </li>
              <li>
                <a href="/" className={styles.active} title="Find My DPD Depot">
                  Find My {local ? "Local" : "DPD"} Depot
                </a>
              </li>
              {!local && (
                <li>
                  <a
                    href={`${domain}/content/how-can-we-help/parcel-delivery-during-covid-19.jsp`}
                    title="Parcel Delivery during COVID-19"
                  >
                    Parcel Delivery during COVID-19
                  </a>
                </li>
              )}
              <li>
                <a
                  href={`${domain}/content/products_services/uk_issues.jsp`}
                  title="Service Disruption"
                >
                  Service Disruption
                </a>
              </li>
              {!local && (
                <li>
                  <a
                    href={`${domain}/apps/delivery_preferences/dpd-your-delivery-preferences.jsp`}
                    title="Your Delivery Preferences"
                  >
                    Your Delivery Preferences
                  </a>
                </li>
              )}
            </ul>
          </li>
          <li>
            <a
              title="MyDPD"
              actionid="4301re6C48kIGOZP"
              href={`${domain}/content/my_dpd/index.jsp`}
            >
              {!local ? "MyDPD" : "MyDPD Local"}
            </a>
            <ul></ul>
          </li>
          <ul></ul>
          {local && (
            <li>
              <a
                title="About DPD Local"
                actionid="nyqTre6C48kIGOnf"
                href={`${domain}/content/about_dpd/index.jsp`}
              >
                About DPD Local
              </a>
              <ul></ul>
            </li>
          )}
          {!local && (
            <li>
              <a
                title="Careers"
                actionid="VrE9re6C48kIGOgj"
                href={`${domain}/careers/index.jsp`}
              >
                Careers
              </a>
              <ul></ul>
            </li>
          )}
          {!local && (
            <li>
              <a
                title="Driving Opportunities"
                actionid="J3B9re6C48kIGOh6"
                href={process.env.REACT_APP_APP_DRIVERS}
              >
                Driving Opportunities
              </a>
              <ul></ul>
            </li>
          )}
          <li>
            <a
              title="Innovation"
              actionid="y_itre6C48kIGOao"
              href={process.env.REACT_APP_APP_INNOVATION}
            >
              Innovation
            </a>
            <ul></ul>
          </li>
          {!local && (
            <li>
              <a
                title="DPD Green"
                actionid="CXH9re6C48kIGOjP"
                href={process.env.REACT_APP_APP_GREEN}
              >
                DPD Green
              </a>
              <ul></ul>
            </li>
          )}
          {!local && (
            <li>
              <a
                title="DPD Life"
                actionid="HuiDre6C48kIGOkh"
                href={process.env.REACT_APP_APP_LIFE}
              >
                DPD Life
              </a>
              <ul></ul>
            </li>
          )}
          {local && (
            <li>
              <a
                title="COVID-19 Latest"
                actionid="M8TDre6C48kIGOlu"
                href={`${domain}/content/products_services/covid-19-updates.jsp`}
              >
                COVID-19 Latest
              </a>
              <ul></ul>
            </li>
          )}
          <li>
            <a
              title="Brexit"
              actionid="Vsedre6C48kIGOfQ"
              href={`${domain}/content/about_dpd/brexit-ready.jsp`}
            >
              Brexit
            </a>
            <ul></ul>
          </li>
          <ul></ul>
          <ul></ul>
          <ul></ul>
        </ul>
      </aside>
    </>
  );
};

export default MobileNav;
