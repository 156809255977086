import styles from "./Directions.module.scss";
import { getMiles, getMinutes } from "../../utils/helpers";

const Directions = ({ data, map }) => {
  const steps = data.routes[0].legs[0].steps;
  const distance = data.routes[0].legs[0].distance;
  const duration = data.routes[0].legs[0].duration;

  const handleFlyto = (e) => {
    const lng = e.currentTarget.getAttribute("lng");
    const lat = e.currentTarget.getAttribute("lat");

    map.current.flyTo({
      center: [lng, lat],
      essential: true,
      zoom: 17,
    });
  };

  const renderListItem = () => {
    return steps.map((step, index) => {
      let icon = "";

      if (
        step.maneuver.type === "rotary" ||
        step.maneuver.type === "roundabout"
      ) {
        icon = `directions-icon-roundabout`;
      } else {
        icon = step.maneuver.modifier
          ? `directions-icon-${step.maneuver.modifier.split(" ").join("")}`
          : `directions-icon-${step.maneuver.type.split(" ").join("")}`;
      }

      return (
        <li
          key={index}
          className={`${styles.liStep} ${steps[index + 1] ? styles.after : ""}`}
          lng={step.maneuver.location[0]}
          lat={step.maneuver.location[1]}
          onClick={(e) => handleFlyto(e)}
        >
          <span className={`${styles.icon} ${icon}`}></span>
          <div>{step.maneuver.instruction}</div>
          {getMiles(step.distance) > 0 ? (
            <div className={styles.distance}>{`${getMiles(
              step.distance
            )}mi`}</div>
          ) : null}
        </li>
      );
    });
  };

  return (
    <div className={styles.control}>
      <div className={styles.container}>
        <div className={styles.routeSummary}>
          <h1>{`${getMiles(distance)}mi`}</h1>
          <span>{`${getMinutes(duration)}min`}</span>
        </div>
        <div className={styles.instructions}>
          <div className={styles.instructionsWrapper}>
            <ol className={styles.steps}>{renderListItem()}</ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Directions;
