import axios from "axios";

export const getDepot = async (postcode, businessUnit, appEngine) => {
  const depotcodeURL = `${appEngine}/v1/depotcode?postcode=${postcode}&businessunit=${businessUnit}`;

  try {
    const depocodeRes = await axios.get(depotcodeURL);

    const depotAddressURL = `${appEngine}/v1/depot?depotcode=${depocodeRes.data.data.depotCode}`;

    const depotAddressRes = await axios.get(depotAddressURL);

    return depotAddressRes.data.data;
  } catch (error) {
    console.log(error);
  }
};
