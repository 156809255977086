import React, { useState, useEffect, useRef, useCallback } from "react";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import styles from "./DepotResults.module.scss";
import Map from "../Mapbox/Map";
import Directions from "../Directions/Directions";
import { getRoute } from "../../utils/getRoute";
import { getDepot } from "../../utils/getDepot";
import { getDayOfWeek } from "../../utils/helpers";

const DepotResults = ({
  mobileNavShowing,
  goBack,
  businessTheme: { local, businessUnit, domain },
  loading,
  address,
  setPostcode,
}) => {
  const [mappingData, setMappingData] = useState("");
  const [depotAddress, setDepotAddress] = useState("");
  const map = useRef(null);
  const mapContainer = useRef(null);

  const getMappingData = useCallback(
    async (lng, lat) => {
      if (depotAddress) {
        const data = await getRoute(
          [lng, lat],
          [
            depotAddress.addressPoint.longitude,
            depotAddress.addressPoint.latitude,
          ]
        );
        setMappingData(data);
      }
    },
    [depotAddress]
  );

  useEffect(() => {
    async function getDepotAddress() {
      const appEngineUrl = local
        ? process.env.REACT_APP_APP_ENGINE_LOCAL
        : process.env.REACT_APP_APP_ENGINE;
      const depotAddressRes = await getDepot(
        address[0].postcode,
        businessUnit,
        appEngineUrl
      );
      setDepotAddress(depotAddressRes);
    }

    if (!loading && address) {
      getDepotAddress();
    }
  }, [address, businessUnit, loading, local]);

  useEffect(() => {
    async function setInitalRoute() {
      getMappingData(address[0].udprnLongitude, address[0].udprnLatitude);
    }

    if (!loading && address) {
      setInitalRoute();
    }
  }, [address, getMappingData, businessUnit, loading]);

  return (
    <div
      className={`${styles.searchContainer} dpd-columns ${
        mobileNavShowing ? styles.moveLeft : styles.revert
      }`}
    >
      <Breadcrumb local={local} domain={domain} />
      {
        <div className={styles.appContent}>
          <div className={styles.module}>
            <div
              className={`${styles.moduleHeading} ${
                local ? styles.localHeading : ""
              }`}
            >
              <h3 className={styles.textHeading}>Depot Finder</h3>
            </div>
            <div className={styles.moduleBody}>
              {!loading ? (
                <div>
                  <div className={styles.formHeader}>
                    <label className={styles.formLabel} for="address_selector">
                      My address is:
                    </label>
                  </div>
                  <div className={styles.formBody}>
                    <div class="row">
                      <div className={styles.labelCol}>
                        <select
                          id="address_selector"
                          onChange={(e) => {
                            getMappingData(
                              address[e.target.value].udprnLongitude,
                              address[e.target.value].udprnLatitude
                            );
                          }}
                        >
                          {address.map((address, index) => {
                            return (
                              <option
                                key={index}
                                value={index}
                                lat={address.udprnLatitude}
                                lng={address.udprnLongitude}
                              >
                                {`${address.postcode}, ${
                                  address.organisation
                                    ? address.organisation
                                    : ""
                                } ${address.property} ${address.street}, ${
                                  address.town
                                }`}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className={styles.formHeader2}>
                    <label className={styles.formLabel}>
                      Your local depot is:
                    </label>
                  </div>
                  <div className={styles.formBody}>
                    <div class="row">
                      <div className={styles.depotName}>
                        {depotAddress && (
                          <strong>{depotAddress.depot.depotName}</strong>
                        )}
                      </div>
                    </div>
                    <div className={styles.depotRow}>
                      {depotAddress && (
                        <div className={styles.depotDetails}>
                          <div>{depotAddress.depot.depotName}</div>
                          <div>{depotAddress.depot.street}</div>
                          <div>{depotAddress.depot.town}</div>
                          <div>{depotAddress.depot.county}</div>
                          <div>{depotAddress.depot.postcode}</div>
                        </div>
                      )}
                      {depotAddress && (
                        <div className={styles.depotTimes}>
                          <div className={styles.openTimeContainer}>
                            <div className={styles.openTitle}>
                              Opening Hours
                            </div>
                            <div className={styles.openTimeDetails}>
                              <div class="row clearfix">
                                {depotAddress.openingHours.map((day, index) => {
                                  const dayofWeek = getDayOfWeek(index);
                                  if (day.startTime && day.endTime) {
                                    const start = day.startTime.substring(0, 5);
                                    const end = day.endTime.substring(0, 5);
                                    return (
                                      <React.Fragment key={index}>
                                        <div className={styles.day}>
                                          {dayofWeek}
                                        </div>
                                        <div
                                          className={styles.time}
                                        >{`${start} - ${end}`}</div>
                                      </React.Fragment>
                                    );
                                  } else {
                                    return (
                                      <React.Fragment key={index}>
                                        <div className={styles.day}>
                                          {dayofWeek}
                                        </div>
                                        <div
                                          className={styles.time}
                                        >{`Closed`}</div>
                                      </React.Fragment>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="depot_directions">
                    <div className={styles.formHeader2}>
                      <label className={styles.formLabel}>
                        Here are the directions to your local depot:
                      </label>
                    </div>
                    <div className={styles.formBody}>
                      <div className={styles.mapRow}>
                        <div
                          id="depotfinder_map"
                          className={styles.mapContainer}
                        >
                          {mappingData && (
                            <Map
                              data={mappingData}
                              map={map}
                              mapContainer={mapContainer}
                            />
                          )}
                        </div>
                        <div
                          id="depotfinder_directions"
                          className={styles.directionsContainer}
                        >
                          {mappingData && (
                            <Directions data={mappingData} map={map} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.actionBar}>
                    <button
                      id="back_button"
                      className={`${styles.button} ${
                        local ? styles.buttonLocal : ""
                      }`}
                      onClick={() => {
                        goBack(false);
                        setDepotAddress("");
                        setMappingData("");
                        setPostcode("");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>
              ) : (
                <div className={styles.spinnerContainer}>
                  <img
                    alt="spinner"
                    src={
                      local
                        ? "assets/dpdlocal-spinner.gif"
                        : "assets/spinner.gif"
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default DepotResults;
