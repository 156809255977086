import React, { useState, useEffect } from "react";
import { TrackerProvider } from "@dpdgroupuk/react-event-tracker";
import tracker from "./utils/analytics";

import Header from "./components/Header/Header";
import Navigation from "./components/Navigation/Navigation";
import SubNavigation from "./components/SubNavigation/SubNavigation";
import Search from "./components/Search/Search";
import SubFooter from "./components/SubFooter/SubFooter";
import Footer from "./components/Footer/Footer";
import MobileNav from "./components/MobileNav/MobileNav";
import DepotResults from "./components/DepotResults/DepotResults";

function App({ businessTheme }) {
  const [showMobileNav, setShowMovileNav] = useState(false);
  const [postcode, setPostcode] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (width >= 640) {
      setShowMovileNav(false);
    }
  }, [width, setShowMovileNav]);

  return (
    <TrackerProvider tracker={tracker}>
      <div className="App">
        <Header businessTheme={businessTheme} />
        <div className="nav-container">
          <MobileNav
            setShowNav={setShowMovileNav}
            showNav={showMobileNav}
            businessTheme={businessTheme}
          />
          <Navigation businessTheme={businessTheme} />
        </div>
        <div className="dpd-row flex">
          <SubNavigation businessTheme={businessTheme} />
          {showResults ? (
            <DepotResults
              mobileNavShowing={showMobileNav}
              goBack={setShowResults}
              setPostcode={setPostcode}
              businessTheme={businessTheme}
              loading={loading}
              address={address}
            />
          ) : (
            <Search
              businessTheme={businessTheme}
              mobileNavShowing={showMobileNav}
              showResults={setShowResults}
              postcode={postcode}
              setPostcode={setPostcode}
              setAddress={setAddress}
              setLoading={setLoading}
            />
          )}
        </div>
        <SubFooter businessTheme={businessTheme} />
        <Footer businessTheme={businessTheme} />
      </div>
    </TrackerProvider>
  );
}

export default App;
