import styles from "./Navigation.module.scss";

const Navigation = ({ businessTheme: { domain, local } }) => {
  return (
    <div className={`dpd-row ${styles.mainNavigation} hide-for-small`}>
      <div className="dpd-row">
        <div
          className={`large-12 ${styles.mainNavigation} dpd-columns navigation`}
        >
          <ul className={`${styles.mainNavUL}  ${local ? styles.local : ""}`}>
            <li className={styles.mainNavLi}>
              <a
                title="Home"
                className={styles.mainNavAnchor}
                actionid="tY6Fre6C48kIGI8q"
                href={`${domain}/index.jsp`}
              >
                Home
              </a>
            </li>
            <li className={styles.mainNavLi}>
              <a
                title="Products &amp; Services"
                className={styles.mainNavAnchor}
                actionid="Avylre6C48kIGOWD"
                href={`${domain}/content/products_services/index.jsp`}
              >
                Products &amp; Services
              </a>
            </li>
            <li className={styles.mainNavLi}>
              <a
                className={`${styles.active} ${styles.mainNavAnchor} ${
                  local ? styles.activeLocal : ""
                }`}
                title="Help"
                actionid="0H_lre6C48kIGOX0"
                href={`${domain}/content/how-can-we-help/index.jsp`}
              >
                Help
              </a>
            </li>
            <li className={styles.mainNavLi}>
              <a
                title="MyDPD"
                className={styles.mainNavAnchor}
                actionid="4301re6C48kIGOZP"
                href={`${domain}/content/my_dpd/index.jsp`}
              >
                {!local ? "MyDPD" : "MyDPD Local"}
              </a>
            </li>
            {local && (
              <li className={styles.mainNavLi}>
                <a
                  title="About DPD local"
                  actionid="nyqTre6C48kIGOnf"
                  className={styles.mainNavAnchor}
                  href={`${domain}/content/about_dpd/index.jsp`}
                >
                  About DPD Local
                </a>
              </li>
            )}
            {local && (
              <li className={styles.mainNavLi}>
                <a
                  title="Brexit"
                  actionid="Vsedre6C48kIGOfQ"
                  className={styles.mainNavAnchor}
                  href={`${domain}/content/about_dpd/brexit-ready.jsp`}
                >
                  Brexit
                </a>
              </li>
            )}
            {local && (
              <li className={styles.mainNavLi}>
                <a
                  title="COVID-19 Latest"
                  actionid="M8TDre6C48kIGOlu"
                  className={styles.mainNavAnchor}
                  href={`${domain}/content/products_services/covid-19-updates.jsp`}
                >
                  COVID-19 Latest
                </a>
              </li>
            )}
            {!local && (
              <li className={styles.mainNavLi}>
                <a
                  title="Careers"
                  className={styles.mainNavAnchor}
                  actionid="VrE9re6C48kIGOgj"
                  href={`${domain}/careers/index.jsp`}
                >
                  Careers
                </a>
              </li>
            )}
            {!local && (
              <li className={styles.mainNavLi}>
                <a
                  title="Driving Opportunities"
                  className={styles.mainNavAnchor}
                  actionid="J3B9re6C48kIGOh6"
                  href={process.env.REACT_APP_APP_DRIVERS}
                >
                  Driving Opportunities
                </a>
              </li>
            )}
            <li className={styles.mainNavLi}>
              <a
                title="Innovation"
                className={styles.mainNavAnchor}
                actionid="y_itre6C48kIGOao"
                href={process.env.REACT_APP_APP_INNOVATION}
              >
                Innovation
              </a>
            </li>
            {!local && (
              <li className={styles.mainNavLi}>
                <a
                  title="DPD Green"
                  className={styles.mainNavAnchor}
                  actionid="CXH9re6C48kIGOjP"
                  href={process.env.REACT_APP_APP_GREEN}
                >
                  DPD Green
                </a>
              </li>
            )}
            {!local && (
              <li className={styles.mainNavLi}>
                <a
                  title="DPD Life"
                  className={styles.mainNavAnchor}
                  actionid="HuiDre6C48kIGOkh"
                  href={process.env.REACT_APP_APP_LIFE}
                >
                  DPD Life
                </a>
              </li>
            )}
            {!local && (
              <li className={styles.mainNavLi}>
                <a
                  title="Brexit"
                  className={styles.mainNavAnchor}
                  actionid="Vsedre6C48kIGOfQ"
                  href="https://www.dpd.co.uk/content/about_dpd/brexit-ready.jsp"
                >
                  Brexit
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
